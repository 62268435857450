import React, { useState, useEffect } from "react";
import "./common.css";
import "antd/dist/antd.min.css";
import { Button, Checkbox, Form, Input, Alert } from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "./footer";
import Header from "./header";
import {resetPassword} from '../utils/services';

const PasswordReset = () => {
  const [params, setParams] = useState(useParams());
  const [resetDone, setResetDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [respMsg, setRespMsg] = useState("");
  const [respType, setRespType] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      window.sessionStorage.removeItem("token");
    }

    if (window.sessionStorage.getItem("user_id")) {
      window.sessionStorage.removeItem("user_id");
    }
  }, []);

  const onFinish = (values) => {
    const reqBody = {
      password: values.password,
      token: params.token,
    };

    resetPassword(reqBody).then((response) => {
      
        if (response.data.status) {
          setRespType("success");
          setRedirect(true);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          setRespType("error");
        }
        setRespMsg(response.data.msg);
        setLoading(false);
        setResetDone(true);

    });

    // axios.post(baseURL, reqBody).then((response) => {
    //   console.log("validated user response ", response.data);
    //   if (response.data.status) {
    //     setRespType("success");
    //     setRedirect(true);
    //     setTimeout(() => {
    //       navigate("/login");
    //     }, 2000);
    //   } else {
    //     setRespType("error");
    //   }
    //   setRespMsg(response.data.msg);
    //   setLoading(false);
    //   setResetDone(true);
    // });
  };

  return (
    <div className="auth-wrapper">
      <Header />
      <div className="form-div">
        <div className="form_div_inner_wrapper">
          <h1>Reset Password</h1>

          {resetDone && <Alert message={respMsg} type={respType} />}
          <br />

          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              {/* prefix={<LockOutlined className="site-form-item-icon" />} */}
              <Input type="password" placeholder="Password" />
            </Form.Item>

            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              className="confirm-password-input-field"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              {/* prefix={<LockOutlined className="site-form-item-icon" />} */}
              <Input type="password" placeholder="Confirm Password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>

          {redirect && (
            <div>
              {/* <Link to="/login">Click here to sign in</Link> */}
              <p>Page will redirect to login automatically.</p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordReset;
