import React, { useState, useEffect } from "react";
import "./common.css";
import "antd/dist/antd.min.css";
import { Button, Form, Input, Alert, Select } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "./footer";
import Header from "./header";
import {signUp} from '../utils/services';

function Signup() {
  const [loading, setLoading] = useState(false);
  const [signup, setSignup] = useState(false);
  const [respMsg, setRespMsg] = useState("");
  const [respType, setRespType] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      window.sessionStorage.removeItem("token");
    }

    if (window.sessionStorage.getItem("user_id")) {
      window.sessionStorage.removeItem("user_id");
    }
  }, []);

  const onFinish = (values) => {

    setLoading(true);

    // checking for email and organization domain match
    // var website = values.organization;
    // var email = values.email;
    // var emailDomain = email.split('@')[1];
    // var websiteDomain = website.match(/^(?:https?:\/\/)?([^\/]+)/)[1];
    // var isValidEmail = (websiteDomain || '').lastIndexOf(emailDomain) === (websiteDomain || '').length - (emailDomain || '').length;

    // if(isValidEmail){

      signUp(values).then((response) => {
          console.log("validated user response ", response.data);
          if (response.data.status) {
            setRespType("success");
          } else {
            setRespType("error");
          }
          setRespMsg(response.data.msg);
          setLoading(false);
          setSignup(true);
          onReset();
      });

      // const baseURL = process.env.SERVER_URL + "/users/sign_up";
      // axios.post(baseURL, values).then((response) => {
      //   console.log("validated user response ", response.data);
      //   if (response.data.status) {
      //     setRespType("success");
      //   } else {
      //     setRespType("error");
      //   }
      //   setRespMsg(response.data.msg);
      //   setLoading(false);
      //   setSignup(true);
      //   onReset();
      // });

    // }else{
    //   setLoading(false);
    //   setSignup(true);
    //   setRespType("error");
    //   setRespMsg('Work email domain not matching with the organization domain. Please enter valid details.');
    // }

    // console.log("Received values of form: ", values,isValidEmail);
    
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleEngagementTypeChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className="auth-wrapper">
      <Header />
      <div className="form-div">
        <div className="form_div_inner_wrapper">
          <h1>Sign Up</h1>

          {/* Form code starts from here */}

          {signup && <Alert message={respMsg} type={respType} />}
          <br />
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              name="fullname"
              rules={[
                { required: true, message: "Please input your Full Name!" },
              ]}
            >
              {/* prefix={<UserOutlined className="site-form-item-icon" />} */}
              <Input placeholder="Full Name" />
            </Form.Item>

            <Form.Item
              name="organization"
              rules={[
                { required: true, message: "Please input your Organization!" },
                {
                  message: "Please input valid URL!",
                  validator: (_, url) => {
                    if (/^[a-z]+:\/\//i.test(url)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please input valid URL!");
                    }
                  },
                },
              ]}
            >
              {/* prefix={<UserOutlined className="site-form-item-icon" />} */}
              <Input placeholder="Organization URL (Example: https://eltropy.com)" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your Email!" },
                { type: "email", message: "Invalid email address!" },
                {pattern: new RegExp(/^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/),message: "Please enter a valid work email address!"}
              ]}
            >
              {/* prefix={<MailOutlined className="site-form-item-icon" />} */}
              <Input placeholder="Work Email" type="email" />
            </Form.Item>

            {/* Engagement type */}
            <Form.Item
              name="engagement_type"
              rules={[
                { required: true, message: "Please input your Engagement Type!" },
              ]}
            >
              <Select
                placeholder="Engagement Type"
                defaultValue={null}
                style={{ width: '100%' }}
                onChange={handleEngagementTypeChange}
                options={[
                  { value: 'partner', label: 'Partner' },
                  { value: 'customer', label: 'Customer' },
                  { value: 'internal', label: 'Internal' },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              {/* prefix={<LockOutlined className="site-form-item-icon" />} */}
              <Input type="password" placeholder="Password" />
            </Form.Item>

            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              className="confirm-password-input-field"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              {/* prefix={<LockOutlined className="site-form-item-icon" />} */}
              <Input type="password" placeholder="Confirm Password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Sign Up
              </Button>
              Already have an Account? <Link to="/login">Sign in</Link>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Signup;
