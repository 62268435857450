import React from 'react';
import EltropyLogo from '../assets/eltropy_logo.png';

function Header() {
  return (
    <div className='header_div_wrapper'>
        <img src={EltropyLogo} style={{width:'150px'}} alt='Eltropy-logo'/>

        <h2>
          Developer Portal
        </h2>

      </div>
  )
}

export default Header