import axios from "axios";

const {REACT_APP_SERVER_URL} = process.env;

const api = axios.create({
    // baseURL: REACT_APP_SERVER_URL
    // baseURL: "http://a6277c0bf7b2b41a488e6d30cc891b7f-1014190177.us-west-2.elb.amazonaws.com"
    baseURL: "https://backend.developer.eltropy.com"
})

export const userLogin = async (data) => {
    try{
        const resp = await api.post('/users/sign_in', data);
        return resp;
    } catch(err){
        console.log(err)
    }
}

export const resetPassword = async (data) => {
    try{
        const resp = await api.post('/users/reset_password', data);
        return resp;
    } catch(err){
        console.log(err)
    }
}

export const validateTokenEmailId = async (data) => {
    try{
        const resp = await api.post('/users/validate_token_email_id', data);
        return resp;
    } catch(err){
        console.log(err)
    }
}

export const signUp = async (data) => {
    try{
        const resp = await api.post('/users/sign_up', data);
        return resp;
    } catch(err){
        console.log(err)
    }
}

export const forgotPassword = async (data) => {
    try{
        const resp = await api.post('/users/forgot_password', data);
        return resp;
    } catch(err){
        console.log(err)
    }
}
