import React, { useState, useEffect } from "react";
import "./common.css";
import "antd/dist/antd.min.css";
import { Button, Form, Input, Alert } from "antd";
import axios from "axios";
import Footer from "./footer";
import Header from "./header";
import {forgotPassword} from '../utils/services';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [submited, setSubmit] = useState(false);
  const [respMsg, setRespMsg] = useState("");
  const [respType, setRespType] = useState("");

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      window.sessionStorage.removeItem("token");
    }

    if (window.sessionStorage.getItem("user_id")) {
      window.sessionStorage.removeItem("user_id");
    }
  }, []);

  const onFinish = (values) => {
    // const baseURL = process.env.SERVER_URL + "/users/forgot_password";

    // axios.post(baseURL, values).then((response) => {
    //   console.log("validated user response ", response.data);
    //   if (response.data.status) {
    //     setRespType("success");
    //   } else {
    //     setRespType("error");
    //   }
    //   setRespMsg(response.data.msg);
    //   setLoading(false);
    //   setSubmit(true);
    // });

    forgotPassword(values).then((response) => {
        if (response.data.status) {
          setRespType("success");
        } else {
          setRespType("error");
        }
        setRespMsg(response.data.msg);
        setLoading(false);
        setSubmit(true);
    });

    setLoading(true);
  };

  return (
    <div className="auth-wrapper">
      <Header />
      <div className="form-div">
        <div className="form_div_inner_wrapper">
          <h1>Forgot Password</h1>

          {submited && <Alert message={respMsg} type={respType} />}
          <br />

          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your Email!" },
                { type: "email", message: "Email is not a valid Email!" },
              ]}
            >
              {/* prefix={<MailOutlined className="site-form-item-icon" />} */}
              <Input placeholder="Enter Email Address" type="email" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
