import React, { useState, useEffect } from "react";
import "./common.css";
import "antd/dist/antd.min.css";
import { Button, Form, Input, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "./footer";
import Header from "./header";
import {userLogin} from '../utils/services';

function Login() {
  const [loading, setLoading] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [respMsg, setRespMsg] = useState("");
  const [respType, setRespType] = useState("");
  const [form] = Form.useForm();
  let navigate = useNavigate();

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      window.sessionStorage.removeItem("token");
    }

    if (window.sessionStorage.getItem("user_id")) {
      window.sessionStorage.removeItem("user_id");
    }
  }, []);

  const onFinish = (values) => {
    setLoading(true);


    userLogin(values).then((response) => {
      console.log("validated user response ", response.data);
      if (response.data.status) {
        setRespType("success");
        window.sessionStorage.setItem("token", response.data.token);
        window.sessionStorage.setItem("user_id", values.email);
        navigate("/eltropy_apis_doc");
      } else {
        setRespType("error");
      }
      setRespMsg(response.data.msg);
      setLoading(false);
      setSignIn(true);
      // onReset();
    });


    // const baseURL = process.env.SERVER_URL + "/users/sign_in";
    // console.log("Received values of form: ", values,process.env.SERVER_URL,baseURL);
    // axios.post(baseURL, values).then((response) => {
    //   console.log("validated user response ", response.data);
    //   if (response.data.status) {
    //     setRespType("success");
    //     window.sessionStorage.setItem("token", response.data.token);
    //     window.sessionStorage.setItem("user_id", values.email);
    //     navigate("/eltropy_apis_doc");
    //   } else {
    //     setRespType("error");
    //   }
    //   setRespMsg(response.data.msg);
    //   setLoading(false);
    //   setSignIn(true);
    //   // onReset();
    // });
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="auth-wrapper">
      <Header />
      <div className="form-div">
        <div className="form_div_inner_wrapper">
          <h1>Sign In</h1>
          {signIn && <Alert message={respMsg} type={respType} />}
          <br />
          {/* Form code starts from here */}
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your Email!" },
                { type: "email", message: "Email is not a valid Email!" },
              ]}
            >
              {/* prefix={<MailOutlined className="site-form-item-icon" />} */}
              <Input placeholder="Email" type="email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              {/* prefix={<LockOutlined className="site-form-item-icon" />} */}
              <Input type="password" placeholder="Password" />
            </Form.Item>

            <Link to="/forgot_password">Forgot password?</Link>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Sign In
              </Button>
              Not registered yet? <Link to="/signup">Create an Account</Link>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
