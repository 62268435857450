import React from "react";
import "./common.css";

function Footer() {
  return (
    <div className="footer_wrapper">
      <p>
        <a
          className="clickable-footer-tag"
          href="https://eltropy.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <span style={{ paddingLeft: "8px", paddingRight: "8px" }}>|</span>
        <a
          className="clickable-footer-tag"
          href="https://eltropy.com/incident-reporting/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Report an Incident
        </a>
      </p>

      <p>
        <span>&#169; 2022 Eltropy, Inc. All Rights Reserved.</span>
      </p>
    </div>
  );
}

export default Footer;
