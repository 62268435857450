import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import {validateTokenEmailId} from '../utils/services';

const PrivateRoutes = () => {
  // let auth = {'token': window.sessionStorage.getItem("token") ? true : false}

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!!window.sessionStorage.getItem("token") && !!window.sessionStorage.getItem("user_id")) {
      // const baseURL =
      //   process.env.SERVER_URL +
      //   "/users/validate_token_email_id";
      let requestBody = {
        token: window.sessionStorage.getItem("token"),
        email: window.sessionStorage.getItem("user_id"),
      };

      validateTokenEmailId(requestBody).then((response) => {
        if (response.data.status) {
          console.log('------ resp ----> ',response)
        setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
        setIsLoading(false);
      });

      // axios.post(baseURL, requestBody).then((response) => {
      //   if (response.data.status) {
      //       console.log('------ resp ----> ',response)
      //     setIsLoggedIn(true);
      //   } else {
      //     setIsLoggedIn(false);
      //   }
      //   setIsLoading(false);
      // });
    }else{
        setIsLoading(false)
    }
  }, []);
  console.log("validated user response ", isLoggedIn, loading);

  return !loading && (isLoggedIn ? <Outlet /> : <Navigate to="/login" />);
};

export default PrivateRoutes;
