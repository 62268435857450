
import React from 'react'
import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";

import Login from './components/login';
import Signup from './components/signup';
import ForgotPassword from './components/forgotPassword';
import PasswordReset from './components/passwordReset';


import PrivateRoutes from './utils/privateRoutes';
import Home from './components/home';

function App() {
  return (

    <>
      
      <Routes>
        <Route element={<PrivateRoutes/>}>
            <Route path='/eltropy_apis_doc' element={<Home/>} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password/:token" element={<PasswordReset />} />
        <Route exact path="/" element={<Login />} />
      </Routes>
      
    </>

  );
}

export default App;
